<template>
  <div v-loading="loaders.vitaminSupplementSubCategories" class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">List of Vitamin and Supplement Sub Categories</h1>
      <div class="d-flex justify-content-end">
        <base-button class="btn-secondary-action title-btn-padding button-font" @click="toggleModal('Save')">Add New</base-button>
      </div>
    </div>
    <div class="mt-4 cardStyle">
      <div id="select-option-container" class="mt-4 ml-4" :class="{invisible: !selectedRows.length}">
        <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                     @click="promptMultiRemove"
                     :loading="loaders.checkboxButton.Delete"
                     :disabled="loaders.vitaminSupplementSubCategories"
        >Delete
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                     @click="handleActivate"
                     :loading="loaders.checkboxButton.Activate"
                     :disabled="loaders.vitaminSupplementSubCategories"
        >Activate
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                     @click="handleDeactivate"
                     :loading="loaders.checkboxButton.DeActive"
                     :disabled="loaders.vitaminSupplementSubCategories"
        >Deactivate
        </base-button>
      </div>
      <el-table
        class="table-responsive table-flush"
        header-row-class-name="thead-light"
        width="100%"
        ref="multipleSelectionTable"
        :data="response.vitaminSupplementSubCategories"
        @selection-change="vitaminSupplementSubCategoriesMultiSelect"
      >
        <el-table-column prop="selected" type="selection" min-width="130px">
        </el-table-column>
        <el-table-column label="Sub Category" prop="name" min-width="130px">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Category" prop="name" min-width="130px">
          <template slot-scope="scope">
            <div class="trim-text-overflow">
              {{ (scope.row.vitamin_supplement_category) ? scope.row.vitamin_supplement_category.name : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" prop="status" min-width="130px">
          <template slot-scope="scope">
              <span :class="['status',getStatus(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatus(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS">
          <div slot-scope="{ $index, row }" class="d-flex">
            <img
              class="pointer"
              @click="toggleModal('Update' , row)"
              src="/img/icons/buttons/edit.svg"
              alt="Edit"
            />
            <img
              class="pointer ml-2"
              @click="handleDelete(row)"
              src="/img/icons/buttons/delete.svg"
              alt="Delete"
            />
          </div>
        </el-table-column>
      </el-table>
      <div class="col-12 d-flex justify-content-end flex-wrap">
        <custom-pagination
          class="mt-4 pagination-no-border float-right"
          v-model="request.pagination.page"
          :per-page="request.pagination.perPage"
          :total="request.pagination.total"
          @input="changePage"
        >
        </custom-pagination>
      </div>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeVitaminSupplementSubCategory"
        @onRemoveMultiple="deleteVitaminSupplementSubCategories"
      />
      <el-dialog
        :title="modal.title"
        :visible.sync="modals.VitaminSupplementSubCategoryModal"
        width="35%"
        :before-close="handleClose"
        :close-on-click-modal="false">
        <el-form role="form" :model="vitaminSupplementSubCategory" ref="formVitaminSupplementSubCategory" :name="modal.formType" >
          <div class="mt--4">
            <label class="form-control-label" for="">Select Vitamin Supplement Category*</label>
            <el-form-item prop="category" :rules="rules.category">
              <el-select
                class="w-100"
                label="Category"
                :rules="{required: true}"
                v-model="vitaminSupplementSubCategory.category">
                <el-option
                  v-for="option in response.vitaminSupplementCategories"
                  class="select-danger"
                  :value="option.id"
                  :label="option.name"
                  :key="option.id">
                </el-option>
              </el-select>
            </el-form-item>
            <label class="form-control-label" for="">Vitamin Supplements Sub Category Name*</label>
            <el-form-item prop="name" :rules="rules.name">
              <el-input
                class=""
                name="Category Name"
                v-model="vitaminSupplementSubCategory.name"
                placeholder="Category Name">
              </el-input>
            </el-form-item>
            <label class="form-control-label" for="">Status</label>
            <el-form-item prop="status" :rules="rules.status">
              <el-select
                class="w-100"
                v-model="vitaminSupplementSubCategory.status">
                <el-option
                  v-for="option in dropdowns.status"
                  class="select-danger"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="text-right mt-4">
            <base-button class="cancel-btn option-btn-font" type="secondary" @click="handleClose">Cancel</base-button>
            <base-button class="btn-primary-action option-btn-font pl-5 pr-5 br-4" :loading="loaders.vitaminSupplementSubCategories" @click="onFormSubmit('formVitaminSupplementSubCategory')" type="success">{{modal.buttonText}}</base-button>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui";
import BackButton from "@/components/Router/BackButton";
import CustomPagination from "../../../../Components/Pagination/CustomPagination";

const RemoveModal = () => import(/* webpackChunkName: "vitaminSupplementSubCategoriesRemoveModal" */ "@/components/Modals/RemoveModal.vue");

export default {
  name: "VitaminSupplementSubCategories",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,BackButton,CustomPagination
  },
  mounted() {
    this.fetchVitaminSupplementSubCategories()
  },
  data() {
    return {
      loaders: {
        vitaminSupplementSubCategories: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      response: {
        vitaminSupplementCategories: [],
        vitaminSupplementSubCategories: [],
      },
      modals : {
        VitaminSupplementSubCategoryModal : false,
      },
      modal : {
        title : 'Add Vitamin and Supplement Sub Category',
        buttonText : 'Save',
        formType: 'Save',
      },
      vitaminSupplementSubCategory : {
        id : '',
        name : '',
        status : 1,
        category : '',
      },
      selectedVitaminSupplementSubCategory : '',
      dropdowns: {
        status: [
          {
            value: 1,
            label: 'Active'
          },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
      rules: {
        name: [
          {required: true, message: 'Please input Sub Category Name', trigger: 'blur'},
          {min: 3, max: 55, message: 'Length should be 3 to 55', trigger: 'blur'}
        ],
        status: [
          {required: true, message: 'Please select status', trigger: 'change'},
        ],
        category: [
          {required: true, message: 'Please select Category', trigger: 'change'},
        ],
      },
      request : {
        pagination : {
          page:1,
          currentPage:1,
          perPage:20,
          total:1,
        }
      },
    };
  },
  methods: {

    fetchVitaminSupplementSubCategories() {
      let vm = this;
      vm.loaders.vitaminSupplementSubCategories = true
      axios.get(this.$store.getters.getBaseUrl + '/api/content/vitamin-supplement-sub-category?page='+vm.request.pagination.page)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.vitaminSupplementSubCategories = response.data.data.data;
          vm.request.pagination.page = response.data.data.current_page;
          vm.request.pagination.perPage = response.data.data.per_page;
          vm.request.pagination.total = response.data.data.total;
        })
        .catch((error) => console.error(error.response))
        .finally(() => vm.loaders.vitaminSupplementSubCategories = false);
    },

    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    getStatus(status) {
      if (status === 0) {
        return 'Inactive'
      }
      return 'Active';
    },

    vitaminSupplementSubCategoriesMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    clearSelection() {
      this.$refs.multipleSelectionTable.clearSelection();
    },

    statusUpdateRequest(ids, status) {
      this.loaders.vitaminSupplementSubCategories = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      };
      axios.post(this.$store.getters.getBaseUrl + `/api/content/vitamin-supplement-sub-category/toggle-status`, payload)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.removeId = []
          this.fetchVitaminSupplementSubCategories()
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.vitaminSupplementSubCategories = false
        })
    },

    handleDelete(row) {
      this.promptRemove(row)
    },

    promptRemove(row) {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove this VitaminSupplement Category.\n' +
        'Deleting Sub Category will remove all Vitamin Supplement associated with it.';
      this.removeId = row.id;
    },

    promptMultiRemove() {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove these VitaminSupplement Categories.\n' +
        'Deleting Sub Category will remove all Vitamin Supplement associated with it.';
      this.removeId = this.extractSelectedIds;
    },

    removeVitaminSupplementSubCategory(id) {
      this.deleteRequest(id)
    },

    deleteVitaminSupplementSubCategories() {
      this.deleteRequest(this.removeId)
    },

    deleteRequest(data) {
      this.loaders.vitaminSupplementSubCategories = true
      this.loaders.checkboxButton.Delete = true
      const payload = { ids: Array.isArray(data) ? data : [data] };
      axios.post(this.$store.getters.getBaseUrl + `/api/content/vitamin-supplement-sub-category/destroy`, payload)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.removeId = [];
          this.fetchVitaminSupplementSubCategories();
          this.$store.commit("hideRemoveModal");
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.checkboxButton.Delete = false;
          this.loaders.vitaminSupplementSubCategories = false;
        })
    },


    toggleModal(modalType ,row){
      if(modalType==='Save'){
        this.modal.title = 'Add Vitamin and Supplement Category';
        this.modal.buttonText = 'Save';
        this.modal.formType = 'Save';
      } else if(modalType==='Update'){
        this.modal.title = 'Update Vitamin and Supplement Category';
        this.modal.buttonText = 'Update';
        this.modal.formType = 'Update';
        this.selectedVitaminSupplementSubCategory = row.id;
        this.fetchVitaminSupplementSubCategory();
      }
      this.fetchVitaminSupplementCategories();
      this.modals.VitaminSupplementSubCategoryModal = !this.modals.VitaminSupplementSubCategoryModal;
    },

    fetchVitaminSupplementSubCategory() {
      let vm = this;
      axios.get(this.$store.getters.getBaseUrl + '/api/content/vitamin-supplement-sub-category/' + this.selectedVitaminSupplementSubCategory)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.vitaminSupplementSubCategory.id = response.data.data.id;
          vm.vitaminSupplementSubCategory.name = response.data.data.name;
          vm.vitaminSupplementSubCategory.status = response.data.data.status;
          vm.vitaminSupplementSubCategory.category = response.data.data.vitamin_supplement_category_id;
        })
        .catch((error) => console.error(error.response));
    },


    fetchVitaminSupplementCategories() {
      let vm = this;
      vm.loaders.vitaminSupplementSubCategories = true
      axios.get(this.$store.getters.getBaseUrl + '/api/content/vitamin-supplement-category/all')
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.vitaminSupplementCategories = response.data.data;
        })
        .catch((error) => console.error(error.response))
        .finally(() => vm.loaders.vitaminSupplementSubCategories = false);
    },

    async onFormSubmit(formName){

      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.$refs.formVitaminSupplementSubCategory.$attrs.name === 'Save') {
            this.storeVitaminSupplementSubCategory();
          } else if (this.$refs.formVitaminSupplementSubCategory.$attrs.name === 'Update') {
            this.updateVitaminSupplementSubCategory();
          }
        } else {
          this.$notify.error({
            title: 'Validation Error',
            message: 'Please check values in the form'
          });
          return false;
        }
      });
    },

    storeVitaminSupplementSubCategory() {
      let vm = this;
      vm.loaders.vitaminSupplementSubCategories = true;
      axios.post(this.$store.getters.getBaseUrl + `/api/content/vitamin-supplement-sub-category/store`, vm.vitaminSupplementSubCategory)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.handleClose();
          this.fetchVitaminSupplementSubCategories();
          this.modals.VitaminSupplementSubCategoryModal = false;
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.vitaminSupplementSubCategories = false
        })
    },

    updateVitaminSupplementSubCategory() {
      let vm = this;
      vm.loaders.vitaminSupplementSubCategories = true;
      axios.post(this.$store.getters.getBaseUrl + `/api/content/vitamin-supplement-sub-category/update`, vm.vitaminSupplementSubCategory)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.handleClose();
          this.fetchVitaminSupplementSubCategories();
          this.modals.VitaminSupplementSubCategoryModal = false;
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.vitaminSupplementSubCategories = false
        })
    },

    handleClose(){
      this.clearVitaminSupplementCategoryForm();
      this.$refs.formVitaminSupplementSubCategory.resetFields();
      this.modals.VitaminSupplementSubCategoryModal = false;
    },

    clearVitaminSupplementCategoryForm() {
      this.vitaminSupplementSubCategory.id = '';
      this.vitaminSupplementSubCategory.name = '';
      this.vitaminSupplementSubCategory.status = 1;
      this.vitaminSupplementSubCategory.category = '';

      this.modal.title = '';
      this.modal.buttonText = '';
      this.modal.formType = '';
      this.selectedVitaminSupplementSubCategory = '';
    },

    changePage(page){
      if(page != this.request.pagination.page ){
        this.request.pagination.page = page;
        this.fetchVitaminSupplementSubCategories();
      }
    },
  },
  computed: {
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
  }
}
</script>

<style scoped>
.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.indicator {
  height: 7px;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

</style>
